<template>
	<div class="main-content">
		<ktv-breadcrumb :title="$t('Notification','Notification')" :page="$t('Broadcast','Broadcast')" :folder="$t('Notification','Notification')" />
		<b-row>
			<b-col cols="4">
				<ktv-accor :title="$t('Form','Form')" :footer="true" name="form-notif">
					<template #content>
						<b-form-group class="col-md-12 mt-2" :label="$t('Title','Title')">
							<b-form-input v-model="param.title" v-validate="'required|max:15'" type="text" maxlength="15" :state="validateState('Title')" name="Title" />
							<b-form-invalid-feedback>
								{{ veeErrors.first("Title") }}
							</b-form-invalid-feedback>
						</b-form-group>
						<b-form-group class="col-md-12 mt-2" :label="$t('Message','Message')">
							<b-textarea v-model="param.message" v-validate="'required|max:30'" rows="3" maxlength="30" :state="validateState('Message')" name="Message" />
							<b-form-invalid-feedback>
								{{ veeErrors.first("Message") }}
							</b-form-invalid-feedback>
						</b-form-group>
					</template>
					<template #footerright>
						<ktv-button v-if="true" 
							:text="$t('Send','Send')" 
							icon="save" 
							color="green-dark" 
							@click="sendNotif()"
						/>
					</template>
				</ktv-accor>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import { KtvButton } from "@/components"

	export default {
		metaInfo: {
			title: "Broadcast Notification",
		},
		components: { KtvButton },
		data() {
			return {
				param: {
					title: "",
					message: "",
				},
				option: {
					target: [],
				}
			}
		},
		methods: {
			sendNotif() {
				this.$validator.validateAll().then((result) => {
					if (!result) {
						return
					}
					this.$swal({
						title: "Are you sure?",
						text: "Notiication will be sent.",
						type: "question",
						showCancelButton: true,
						confirmButtonText: "Submit",
						showLoaderOnConfirm: true,
						preConfirm: () => {
							return this.$http.Post(this.param, this.$urlApi.notification.createNotif)
								.then((response) => { return response })
						},
						allowOutsideClick: () => !this.$swal.isLoading(),
					}).then((res) => {
						if (res.value.success) {
							this.$swal("Success!", "Notification has been sent.", "success")
							this.param.title = "";
							this.param.message = "";
						} else {
							this.$swal("Error!", "Notification failed to be sent.", "error")
						}
					})
				})

			}
		},
	}
</script>
